






import { defineComponent } from '@vue/composition-api';
import CompletedChatsTable from './CompletedChatsTable.vue';

export default defineComponent({
  components: {
    CompletedChatsTable,
  },
});
