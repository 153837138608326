













































































import { FetchCheckupResponsesRequested } from '@/+state/checkup-responses/events';
import {
  checkupResponses as checkupResponsesProjection,
  checkupResponsesLoading,
  checkupResponsesRenderComponent,
} from '@/+state/checkup-responses/projections';
import { CompletedChatsTablePageUpdated } from '@/+state/checkups/events';
import {
  checkupsAll,
  checkupsCount as checkupsCountProjection,
  checkupsLoading,
  checkupsRenderComponent,
  checkupsTableOptions,
} from '@/+state/checkups/projections';
import { surveySessionsNextChat } from '@/+state/survey-sessions/projections';
import { TABLE_CONFIG } from '@/constants/tableConfig';
import BlueCard from '@/shared/components/BlueCard.vue';
import EmptyState from '@/shared/components/EmptyState.vue';
import StatusIndicatorIcon from '@/shared/components/StatusIndicatorIcon.vue';
import { dateFormatter } from '../../../../../shared/date-formatter';
import { emit, select } from '@conversa/reflex';
import { defineComponent, ref } from '@vue/composition-api';
import { capabilitiesStore } from '../../+state/capabilities/store';

export default defineComponent({
  components: { StatusIndicatorIcon, BlueCard, EmptyState },
  setup() {
    const capabilities = capabilitiesStore.inject();
    const expanded = ref([]);

    const checkups = select(checkupsAll);
    const checkupsCount = select(checkupsCountProjection);

    const nextChat = select(surveySessionsNextChat);
    const nextChatDate = dateFormatter(
      nextChat.value?.unformattedDate,
      capabilities.capabilities.international.datetimeFormat,
      'date',
    );

    const checkupResponses = select(checkupResponsesProjection);

    return {
      headers: TABLE_CONFIG.checkups.headers,
      headers2: TABLE_CONFIG.checkups.responses.headers,
      options: select(checkupsTableOptions),
      expanded,
      checkups,
      checkupsLoading: select(checkupsLoading),
      renderCheckupsTable: select(checkupsRenderComponent),
      checkupsCount,
      nextChat,
      nextChatDate,
      shouldHideFooter:
        checkupsCount.value < TABLE_CONFIG.checkups.tableRows + 1,
      checkupResponses,
      checkupResponsesLoading: select(checkupResponsesLoading),
      checkupResponsesRenderComponent: select(checkupResponsesRenderComponent),
      hideResponsesHeader: responses => !responses?.length,
      updatePage: newPage => {
        emit(
          CompletedChatsTablePageUpdated({
            page: newPage,
          }),
        );
      },
      handleExpanded: ({ item: checkup, value: isExpanded }) => {
        isExpanded &&
          !checkupResponses.value[checkup.checkupId]?.length &&
          emit(
            FetchCheckupResponsesRequested({ checkupId: checkup.checkupId }),
          );
      },
      closeExpanded: () => {
        expanded.value = [];
      },
    };
  },
});
