var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderCheckupsTable)?_c('BlueCard',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeExpanded),expression:"closeExpanded"}]},[_c('v-data-table',{attrs:{"mobile-breakpoint":"200","show-expand":"","item-key":"checkupId","headers":_vm.headers,"items":_vm.checkups,"hide-default-header":!_vm.checkups.length,"server-items-length":_vm.checkupsCount,"options":_vm.options,"loading":_vm.checkupsLoading,"expanded":_vm.expanded,"hide-default-footer":_vm.shouldHideFooter,"footer-props":{
      disableItemsPerPage: true,
      showCurrentPage: true,
      showFirstLastPage: true,
    }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.updatePage,"item-expanded":_vm.handleExpanded},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',{staticClass:"no-data"},[_vm._v(" The patient has not taken any chats. "),(_vm.nextChat)?_c('span',[_vm._v(" The next scheduled chat for this patient is "),_c('span',{staticClass:"chat-date"},[_vm._v(_vm._s(_vm.nextChatDate))])]):_vm._e()])]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('StatusIndicatorIcon',{attrs:{"status":item.status}})],1)]}}],null,true)},[_c('span',[_vm._v("Color Status: "+_vm._s(item.status))])])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [(_vm.checkupResponsesRenderComponent[item.checkupId])?_c('td',{staticClass:"responses-table",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":"200","no-data-text":"Responses are still being computed.","loading-text":"Fetching your results...","loading":_vm.checkupResponsesLoading[item.checkupId],"headers":_vm.headers2,"items":_vm.checkupResponses[item.checkupId],"items-per-page":-1,"hide-default-header":_vm.hideResponsesHeader(_vm.checkupResponses[item.checkupId])},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('StatusIndicatorIcon',{attrs:{"status":item.status}})],1)]}}],null,true)},[_c('span',[_vm._v("Color Status: "+_vm._s(item.status))])])]}}],null,true)})],1):_vm._e()]}}],null,false,882882641)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }